import
  {
    Box,
    Button,
    ButtonGroup,
    Link as CLink,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
    VStack,
  } from "@chakra-ui/react";
import { PhoneField } from "@equidefi/portals/components/Auth";
import { InfoArea } from "@equidefi/portals/components/InfoArea";
import
  {
    useGetUserById,
    useUpdateUserById,
  } from "@equidefi/portals/hooks/useUsers";
import { ENTITY_TYPE_OPTIONS } from "@equidefi/shared/constants/profile";
import { Input } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

import { EntityTypeIcon } from "@equidefi/portals/components/EntityTypeIcon";
import { MESSAGE } from "../../constants/forms";
import { isAdmin } from "../../helpers";

const profileSchema = Yup.object().shape({
  phone: Yup.string()
    .nullable()
    .test({
      name: "phone",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : true),
    }),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Please enter your email address"),
  company: Yup.string()
    .nullable()
    .when("entity_type", {
      is: "ENTITY",
      then: Yup.string().trim().required(MESSAGE.REQUIRED("Company Name")),
    }),
});

const EditModal = ({
  isOpen = false,
  investment,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const { data: user } = useGetUserById(investment?.user_id);
  const updateUser = useUpdateUserById(investment?.user_id);
  const toast = useToast();

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        await updateUser.mutateAsync(data);
        onSubmit();
        toast({
          status: "success",
          description: `The user's profile has been updated successfully`,
        });
        onClose(false);
      } catch (error) {
        console.error(error);
        toast({
          status: "error",
          description: error.response.data.errors.join(". "),
        });
      }
    },
    [onClose, onSubmit, toast]
  );

  const initialValues = {
    first_name: user?.first_name,
    middle_name: user?.middle_name,
    last_name: user?.last_name,
    company: user?.company,
    title: user?.title,
    email: user?.email,
    phone: user?.phone,
    address_street_1: user?.address_street_1 || "",
    address_street_2: user?.address_street_2 || "",
    address_city: user?.address_city || "",
    address_state: user?.address_state || "",
    address_postal_code: user?.address_postal_code || "",
    address_country: user?.address_country || "",
    entity_type: user?.entity_type || "INDIVIDUAL",
  };

  const defaultEntityType = useMemo(() => {
    return ENTITY_TYPE_OPTIONS.find(
      (option) => option.value === initialValues.entity_type
    );
  }, [initialValues.entity_type]);

  const { handleSubmit, errors, touched, getFieldProps, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      onSubmit: handleOnSubmit,
      initialValues,
      validationSchema: profileSchema,
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    });

  return (
    <Modal centered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>Edit User Profile</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                label="First Name"
                placeholder="John"
                isInvalid={errors.first_name && touched.first_name}
                error={errors.first_name}
                {...getFieldProps("first_name")}
              />

              <Input
                label="Middle Name"
                isInvalid={errors.middle_name && touched.middle_name}
                error={errors.middle_name}
                {...getFieldProps("middle_name")}
              />

              <Input
                label="Last Name"
                isInvalid={errors.last_name && touched.last_name}
                error={errors.last_name}
                {...getFieldProps("last_name")}
              />

              <Input
                label="Title"
                placeholder="e.g. Investor"
                isInvalid={errors.title && touched.title}
                error={errors.title}
                {...getFieldProps("title")}
              />

              <Input
                label="Company"
                placeholder="e.g. EquiDeFi"
                isInvalid={errors.company && touched.company}
                error={errors.company}
                {...getFieldProps("company")}
              />

              <Input
                isRequired
                label="Email Address"
                type="email"
                placeholder="e.g. address@example.com"
                {...getFieldProps("email")}
              />

              <PhoneField
                isRequired={false}
                error={errors?.phone}
                setFieldValue={setFieldValue}
                {...getFieldProps("phone")}
              />

              <Input
                label="Street Address"
                isInvalid={
                  touched.address_street_1 && !!errors.address_street_1
                }
                error={errors.address_street_1}
                {...getFieldProps("address_street_1")}
              />
              <Input
                label="Street Address 2 (Unit #)"
                isInvalid={
                  touched.address_street_2 && !!errors.address_street_2
                }
                error={errors.address_street_2}
                {...getFieldProps("address_street_2")}
              />
              <Input
                label="City"
                isInvalid={touched.address_city && !!errors.address_city}
                error={errors.address_city}
                {...getFieldProps("address_city")}
              />
              <Input
                label="State"
                isInvalid={touched.address_state && !!errors.address_state}
                error={errors.address_state}
                {...getFieldProps("address_state")}
              />
              <Input
                label="Postal Code"
                isInvalid={
                  touched.address_postal_code && !!errors.address_postal_code
                }
                error={errors.address_postal_code}
                {...getFieldProps("address_postal_code")}
              />
              <Input
                label="Country"
                isInvalid={touched.address_country && !!errors.address_country}
                error={errors.address_country}
                {...getFieldProps("address_country")}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button isLoading={updateUser.isLoading} type="submit">
                  Save
                </Button>
                <Button colorScheme="gray" onClick={() => onClose(false)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

const InvestorProfile = ({ user, investment, onSubmit = () => {} }) => {
  const modal = useDisclosure();
  const canEdit = isAdmin(user);

  const telephone = useMemo(() => {
    if (!investment?.vault?.phone_number) return null;

    return parsePhoneNumber(investment.vault.phone_number);
  }, [investment]);

  const handleOnSubmit = useCallback(() => {
    onSubmit();
    modal.onClose();
  }, [onSubmit, modal]);

  if (!investment?.vault) {
    return null;
  }

  return (
    <>
      <InfoArea title="Vault Details" width="full">
        <Flex
          as="span"
          justify="space-between"
          align="left"
          direction="column"
          gap={2}
          px={0}
          py={3}
        >
          <Flex fontSize="lg" align="center" gap={2}>
            <EntityTypeIcon size="0.85em" type={investment.vault.entity_type} />

            <Box>{investment.vault.display_name ?? "Unknown Type"}</Box>
          </Flex>
          <Flex color="gray.400" align="center" gap={2}>
            <Icon.Mail size=".85em" />
            <CLink
              href={`mailto:${investment.user.email}`}
              rel="external noreferrer noopener"
              target="_blank"
              textDecor="underline"
            >
              {investment.user.email}
            </CLink>
          </Flex>
          <Flex color="gray.400" align="center" gap={2}>
            <Icon.Phone size=".85em" />
            <Box
              as={telephone ? CLink : "span"}
              href={telephone?.getURI()}
              textDecor={telephone ? "underline" : null}
            >
              {telephone ? telephone.formatInternational() : "N/A"}
            </Box>
          </Flex>

          <Flex color="gray.400" align="start" gap={2}>
            <Box mt="1">
              <Icon.MapPin size=".85em" />
            </Box>
            <VStack align="start" gap={0} color="gray.400">
              <Text p="0" m="0">
                {investment?.vault?.address_street_1}{" "}
                {investment?.vault?.address_street_2}
              </Text>
              <Text m="0">
                {investment?.vault?.address_city}{" "}
                {investment?.vault?.address_state}{" "}
                {investment?.vault?.address_postal_code}
              </Text>
              <Text m="0">{investment?.vault?.address_country_code}</Text>
            </VStack>
          </Flex>
        </Flex>
      </InfoArea>
      {canEdit && (
        <EditModal
          onSubmit={handleOnSubmit}
          onClose={modal.onClose}
          isOpen={modal.isOpen}
          investment={investment}
        />
      )}
    </>
  );
};

export default InvestorProfile;
