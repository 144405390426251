import { Box } from "@chakra-ui/react";
import { Icon } from "../../icon";

export type SortDirection = "asc" | "desc" | null;

interface SortIconProps {
  direction: SortDirection;
  size?: string;
}

export const SortIcon: React.FC<SortIconProps> = ({
  direction,
  size = "1.3em",
}) => {
  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      ml="2"
      verticalAlign="middle"
      height={`calc(${size} * 2)`}
      justifyContent="center"
      gap="0"
    >
      <Icon.ChevronUp
        size={size}
        color="gray"
        style={{
          strokeWidth: direction === "asc" ? 4 : 2,
        }}
      />
      <Icon.ChevronDown
        size={size}
        color="gray"
        style={{
          strokeWidth: direction === "desc" ? 4 : 2,
        }}
      />
    </Box>
  );
};
