import InvestmentClient from "@/clients/InvestmentClient";
import PaginationRow from "@/components/PaginationRow";
import SearchBar from "@/components/SearchBar";
import InfoCard from "@/components/card";
import ExportInvestors from "@/components/investments/modals/ExportInvestors";
import Spinner from "@/components/spinner";
import { INQUIRY_STATUSES } from "@/constants/inquiries";
import { isAdmin, isOfferingOwner } from "@/helpers";
import
  {
    useOfferingFull,
    useOfferingInvestments,
    useOfferingSummary,
  } from "@/hooks/useOfferings";
import AppHeader from "@/layouts/header";
import
  {
    Badge,
    Box,
    Button,
    Checkbox,
    Link as CLink,
    Tooltip as CTooltip,
    Flex,
    IconButton,
    LinkBox,
    LinkOverlay,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Stack,
    Tag,
    Text,
    useToast,
    useToken,
    VStack,
  } from "@chakra-ui/react";
import { EntityTypeIcon } from "@equidefi/portals/components/EntityTypeIcon";
import
  {
    EventBadge,
    StatusBadge,
  } from "@equidefi/portals/components/investments/StatusBadge";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useQueryParams } from "@equidefi/portals/hooks/useQueryParam";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { capitalize } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { parsePhoneNumber } from "react-phone-number-input";
import { Link, useParams } from "react-router";
import Swal from "sweetalert";

const WorkflowEventList = [
  "REGISTRATION",
  "PROFILE",
  "SUBSCRIPTION",
  "PAYMENT",
  "ADDRESS",
  "SIGNERS",
  "KYC",
  "AGREEMENT",
  "ACCREDITATION",
  "DILIGENCE",
  "COUNTERSIGN",
  "CLOSING",
];

const Investors = () => {
  const { id } = useParams();
  const [green] = useToken("colors", ["green.600"]);

  const queryParams = useQueryParams({
    search: { type: "string" },
    page: { defaultValue: 1, type: "number" },
    current: { type: "string" },
    kyc: { type: "string" },
    status: { type: "string" },
    capturable: { defaultValue: false, type: "boolean" },
    funded: { defaultValue: false, type: "boolean" },
  });

  const { data: me } = useCurrentUser();

  const toast = useToast();

  const investmentApi = useApi(InvestmentClient);

  const { data } = useOfferingFull(id);
  const { data: metrics } = useOfferingSummary(id);
  const { offering } = data ?? {};
  const [showExport, setExportModal] = useState(false);
  const {
    data: investmentData,
    refetch: getData,
    isFetching,
  } = useOfferingInvestments(id, {
    searchPhrase: queryParams.get("search"),
    status: queryParams.get("status"),
    current: queryParams.get("current"),
    kycStatus: queryParams.get("kyc"),
    expedited: queryParams.get("expedited"),
    capturable: queryParams.get("capturable"),
    page: queryParams.get("page"),
    funded: queryParams.get("funded"),
  });

  const hasOwnerControl = useMemo(
    () => isOfferingOwner(me, offering?.id) || isAdmin(me),
    [me, offering]
  );

  const { investments = [], pagination = {} } = investmentData ?? {};

  const progressBarLength = useMemo(() => {
    return (metrics?.progress || 0) < 5 ? 5 : metrics?.progress;
  }, [metrics?.progress]);

  // Refers a rule defined here:
  // https://github.com/EquiDeFi/platform/pull/671#issuecomment-2274586047
  const displayProgressNumber = useMemo(() => {
    const progress = metrics?.progress || 0;

    if (progress === 0) return 0;
    if (progress === 100) return 100;

    if (progress > 0 && progress < 1) return 1;
    if (progress > 99 && progress < 100) return 99;

    return Math.round(progress);
  }, [metrics?.progress]);

  const rejectInvestor = async (id) => {
    const status = await Swal({
      title: `Reject`,
      text: `Are you sure you want to reject this investor?`,
      buttons: ["Cancel", "Reject"],
      icon: "warning",
      dangerMode: true,
    });

    if (status) {
      await investmentApi.reject(id);
      getData();
      toast({ status: "success", description: "Investor has been deleted" });
    }
  };

  if (isFetching) {
    return <Spinner show />;
  }

  return (
    <>
      <AppHeader id={id} name="Investors" title={offering?.name} />

      <div className="container-fluid">
        {
          <div id="progress" className="progress" style={{ height: 24 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progressBarLength}%` }}
            >
              {displayProgressNumber}%
            </div>
          </div>
        }

        <div id="summary">
          <div className="row g-3 mt-3">
            <InfoCard
              title="Maximum Offering"
              tooltip="The total amount of funds to potential be raised (not including the optional maximum overage)"
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={offering?.total_amount}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Potential Investors"
              tooltip="The number of investors that have signed up for the offering (minus anyone rejected or refunded)"
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.potentials}
              />
            </InfoCard>
            <InfoCard
              title="Funds Indicated"
              tooltip="The total amount of funds investors have submitted on the subscription page of the workflow."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.indicated}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Total Funds Received"
              tooltip="The total amount of funds that have cleared via credit card/ACH or have been sent manually via check or wire transfer."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.received}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
          </div>
          <div className="row g-3">
            <InfoCard
              title="Funds Available for Closing"
              tooltip="The amount of funds awaiting to be added to the next closing tranche."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.available}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Closed funds"
              tooltip="The total amount of funds that have been added to a closing tranche and transfered from escrow to the issuer bank account."
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.closed}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
            <InfoCard
              title="Balance Available to Raise"
              tooltip="Maximum offering amount minus the total funds received"
            >
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={metrics?.remaining}
                prefix="$"
                decimalScale={0}
              />
            </InfoCard>
          </div>
        </div>

        <PaginationRow
          name="investor"
          pagination={pagination}
          onPageChange={(page) => queryParams.set({ page })}
        />

        <Paper my={3}>
          <Box p="3" px="5" className="card-header">
            <Flex gap="2" flexDirection="row" alignItems="center">
              <SearchBar
                defaultSearchPhrase={queryParams.get("search")}
                onClear={() => {
                  queryParams.set({
                    page: 1,
                    search: undefined,
                  });
                }}
                onSearch={(phrase) => {
                  queryParams.set({
                    page: 1,
                    search: phrase,
                  });
                }}
                placeholder="Search Investors..."
              />
              <Checkbox
                isChecked={queryParams.has("capturable")}
                onChange={(e) => {
                  queryParams.set({
                    page: 1,
                    capturable: e.target.checked ? true : undefined,
                  });
                }}
              >
                Capturable
              </Checkbox>

              <Checkbox
                isChecked={queryParams.has("funded")}
                onChange={(e) => {
                  queryParams.set({
                    page: 1,
                    funded: e.target.checked ? true : undefined,
                  });
                }}
              >
                Funded
              </Checkbox>

              <Checkbox
                isChecked={queryParams.has("expedited")}
                onChange={(e) => {
                  queryParams.set({
                    page: 1,
                    expedited: e.target.checked ? true : undefined,
                  });
                }}
              >
                Expedited
              </Checkbox>

              <Menu>
                <MenuButton
                  rightIcon={<Icon.ChevronDown size="1em" />}
                  size="sm"
                  as={Button}
                  colorScheme="gray"
                  textTransform="capitalize"
                  minWidth="auto"
                >
                  Status:{" "}
                  {queryParams.get("status")
                    ? queryParams.get("status").toLocaleLowerCase()
                    : "Any"}
                </MenuButton>
                <MenuList zIndex="dropdown">
                  <MenuOptionGroup defaultValue={queryParams.get("status")}>
                    <MenuItemOption
                      onClick={() => {
                        queryParams.set({
                          page: 1,
                          status: undefined,
                        });
                      }}
                      value=""
                    >
                      Any
                    </MenuItemOption>
                    {[
                      "PENDING",
                      "REVIEW",
                      "APPROVED",
                      "COMPLETED",
                      "REJECTED",
                      "CLOSED",
                    ].map((evt) => {
                      return (
                        <MenuItemOption
                          key={evt}
                          onClick={() => {
                            queryParams.set({
                              page: 1,
                              status: evt,
                            });
                          }}
                          value={evt}
                          textTransform="capitalize"
                        >
                          {evt.toLowerCase()}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  rightIcon={<Icon.ChevronDown size="1em" />}
                  size="sm"
                  as={Button}
                  colorScheme="gray"
                  textTransform="capitalize"
                  minWidth="auto"
                >
                  Current Step:{" "}
                  {queryParams.get("current")
                    ? queryParams.get("current").toLocaleLowerCase()
                    : "Any"}
                </MenuButton>
                <MenuList rootProps={{ maxH: "100px" }} zIndex="dropdown">
                  <MenuOptionGroup defaultValue="">
                    <MenuItemOption
                      onClick={() => {
                        queryParams.set({ page: 1, current: undefined });
                      }}
                      value=""
                    >
                      Any
                    </MenuItemOption>
                    {WorkflowEventList.map((evt) => {
                      return (
                        <MenuItemOption
                          key={evt}
                          onClick={() => {
                            queryParams.set({ page: 1, current: evt });
                          }}
                          value={evt}
                          textTransform="capitalize"
                        >
                          {evt.toLowerCase()}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  rightIcon={<Icon.ChevronDown size="1em" />}
                  size="sm"
                  as={Button}
                  colorScheme="gray"
                  textTransform="capitalize"
                  minWidth="auto"
                >
                  KYC:{" "}
                  {queryParams.get("kyc")
                    ? queryParams.get("kyc").toLocaleLowerCase()
                    : "Any"}
                </MenuButton>
                <MenuList zIndex="dropdown">
                  <MenuOptionGroup defaultValue={queryParams.get("kyc")}>
                    <MenuItemOption
                      onClick={() => {
                        queryParams.set({
                          page: 1,
                          kyc: undefined,
                        });
                      }}
                      value=""
                    >
                      Any
                    </MenuItemOption>
                    {INQUIRY_STATUSES.map((evt) => {
                      return (
                        <MenuItemOption
                          key={evt}
                          onClick={() => {
                            queryParams.set({
                              page: 1,
                              kyc: evt,
                            });
                          }}
                          value={evt}
                          textTransform="capitalize"
                        >
                          {evt.toLowerCase()}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <Button
                width={270}
                size="sm"
                disabled={!hasOwnerControl}
                onClick={() => setExportModal(true)}
              >
                <Icon.HardDrive size=".9em" style={{ marginRight: "5px" }} />{" "}
                Export
              </Button>
            </Flex>
          </Box>

          <div className="table-responsive">
            <table className="table table-hover table-nowrap card-table">
              <thead>
                {investments.length ? (
                  <tr>
                    <th></th>
                    <th>Investor</th>
                    <th>Start Date</th>
                    {!`${offering?.stock_type}`.split(" ").includes("Safe") && (
                      <th className="text-center">Subscription</th>
                    )}

                    <th className="text-end">Amount</th>
                    <th className="text-center">Expires</th>
                    <th className="text-center">Expedited</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Current Step</th>
                    {isAdmin(me) && <th className="text-center">Action</th>}
                  </tr>
                ) : (
                  <tr className="noDataExist">
                    <th>No data exist</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {investments.map((item, index) => {
                  const telephone = item.vault?.phone_number
                    ? parsePhoneNumber(item.vault.phone_number)
                    : null;

                  const entityType = item.vault?.entity_type;

                  return (
                    <tr key={index}>
                      <Box as="td" color="gray.400">
                        <CTooltip label={capitalize(entityType ?? "Unknown")}>
                          <EntityTypeIcon type={entityType} />
                        </CTooltip>
                      </Box>
                      <Box as="td" className="align-baseline">
                        <LinkBox as={Stack} gap={0}>
                          <Text as="span">
                            <LinkOverlay
                              as={Link}
                              to={`/offering/${id}/investment/${item.id}/overview`}
                            >
                              {item.vault.display_name}
                            </LinkOverlay>
                          </Text>
                          <Flex color="gray.400" align="center" gap={2}>
                            <Icon.Mail size=".85em" />
                            <CLink
                              href={`mailto:${item.user.email}`}
                              rel="external noreferrer noopener"
                              target="_blank"
                              textDecor="underline"
                            >
                              {item.user.email}
                            </CLink>
                          </Flex>
                          <Flex color="gray.400" align="center" gap={2}>
                            <Icon.Phone size=".85em" />
                            <Box
                              as={telephone ? CLink : "span"}
                              href={telephone?.getURI()}
                              textDecor={telephone ? "underline" : null}
                            >
                              {telephone
                                ? telephone.formatInternational()
                                : "N/A"}
                            </Box>
                          </Flex>
                        </LinkBox>
                      </Box>
                      <td className="align-baseline">
                        {moment(item.create_date).format("ll")}
                      </td>
                      {!`${offering?.stock_type}`
                        .split(" ")
                        .includes("Safe") && (
                        <td className="text-center align-baseline">
                          {item.approved_quantity &&
                          item?.approved_quantity !== item?.quantity ? (
                            <td>
                              <div>
                                <NumberFormat
                                  value={item.approved_quantity}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={0}
                                />
                              </div>
                              <del className="small text-muted">
                                <NumberFormat
                                  value={item.quantity}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={0}
                                />
                              </del>
                            </td>
                          ) : (
                            <NumberFormat
                              value={item.quantity}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          )}
                        </td>
                      )}

                      <td className="text-end align-baseline">
                        {item.approved_amount &&
                        item?.amount !== item?.approved_amount ? (
                          <>
                            <Flex justify="end" align="center" gap={2}>
                              {item.is_funded && (
                                <CTooltip label="Funded">
                                  <Icon.CheckCircle
                                    aria-hidden="true"
                                    color={green}
                                    size="1em"
                                  />
                                </CTooltip>
                              )}

                              <NumberFormat
                                value={item.approved_amount}
                                displayType="text"
                                thousandSeparator
                                decimalScale={0}
                                prefix="$"
                              />
                            </Flex>
                            <del className="small text-muted">
                              <NumberFormat
                                value={item.amount}
                                displayType="text"
                                thousandSeparator
                                decimalScale={0}
                                prefix="$"
                              />
                            </del>
                          </>
                        ) : (
                          <Flex justify="end" align="center" gap={2}>
                            {item.is_funded && (
                              <CTooltip label="Funded">
                                <Icon.CheckCircle
                                  aria-hidden="true"
                                  color={green}
                                  size="1em"
                                />
                              </CTooltip>
                            )}

                            <NumberFormat
                              value={item.amount}
                              displayType="text"
                              thousandSeparator
                              decimalScale={0}
                              prefix="$"
                            />
                          </Flex>
                        )}
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        {item.capturable.is_capturable && (
                          <VStack gap="0">
                            {!item.capturable.is_expired ? (
                              <Text
                                as="span"
                                mb="0"
                                textStyle="context"
                                color="black"
                              >
                                {moment(item.capturable.expire_date).format(
                                  "ll"
                                )}
                              </Text>
                            ) : (
                              <Badge size="sm" colorScheme="red">
                                Expired
                              </Badge>
                            )}
                          </VStack>
                        )}
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        <Flex justify="center" align="center" gap={2}>
                          {item.expedited && (
                            <CTooltip label="Expedited Investment">
                              <Icon.CheckCircle
                                aria-hidden="true"
                                color={green}
                                size="1em"
                              />
                            </CTooltip>
                          )}
                        </Flex>
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        <StatusBadge as={Tag} size="sm" action={item.status} />
                      </td>
                      <td className="text-center align-baseline vertical-align-middle">
                        {item?.event_history?.[0]?.workflow_action &&
                          item?.next && (
                            <EventBadge
                              as={Badge}
                              isPlain
                              size="sm"
                              action={item?.event_history?.[0]?.workflow_action}
                              event={item?.next}
                            />
                          )}
                      </td>
                      {isAdmin(me) && (
                        <td className="text-center align-baseline">
                          <CTooltip
                            label="Reject Investor"
                            hasArrow
                            placement="auto"
                          >
                            <IconButton
                              size="xs"
                              colorScheme="gray"
                              onClick={() => rejectInvestor(item.id)}
                              aria-label="Reject Investor"
                              icon={<Icon.XCircle color="red" size="1em" />}
                            />
                          </CTooltip>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Paper>

        <PaginationRow
          name="investor"
          pagination={pagination}
          onPageChange={(page) => queryParams.set({ page })}
        />

        <ExportInvestors
          show={showExport}
          offeringId={id}
          onHide={() => setExportModal(false)}
        />
      </div>
    </>
  );
};

export default Investors;
