import {
  Table as ChakraTable,
  Flex,
  Skeleton,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import {
  ColumnDef,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { V2Theme } from "../../theme";
import { SortIcon } from "./SortIcon";

export type TTableProps<T extends unknown> = {
  data: T[];
  columns: ColumnDef<T>[];
  size?: "sm" | "md" | "lg";
  variant?: "list" | string;
  isLoading?: boolean;
  sorting?: TTableSort;
  defaultSort?: SortingState;
};

const useTableSortBase = (sorting: SortingState = []) => {
  return useState<SortingState>(() => sorting ?? []);
};

export type TTableSort = ReturnType<typeof useTableSortBase>;

export const useTableSort = (
  options: Partial<{
    defaultValue: string;
    // eslint-disable-next-line no-unused-vars
    onChange: (data: { sorting: SortingState; sortBy?: string }) => void;
  }>
) => {
  const { defaultValue, onChange } = options ?? {};
  const tableSort = useTableSortBase(queryToSort(defaultValue));

  const [sorting] = tableSort;
  const sortBy = sortToQuery(sorting?.at(0));

  useEffect(() => {
    onChange?.({ sorting, sortBy });
  }, [sorting, sortBy]);

  return {
    tableSort,
    sortBy,
  };
};

export const sortToQuery = (sort?: ColumnSort) => {
  if (!sort) return undefined;

  return `${sort.id}:${sort.desc ? "desc" : "asc"}`;
};

export const queryToSort = (sortBy?: string) => {
  if (!sortBy) return undefined;

  const [id, direction = "desc"] = String(sortBy).split(":") || [];

  return [
    {
      id,
      desc: direction === "desc",
    },
  ];
};

export const Table = <TData extends unknown>(props: TTableProps<TData>) => {
  const {
    size = "md",
    columns,
    isLoading = false,
    data,
    variant = "list",
    sorting: sortingState,
    ...rest
  } = props;

  const tableData = useMemo(
    () => (isLoading ? Array(25).fill({}) : data),
    [isLoading, data]
  );

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton w="80%" h="1.5rem" />,
          }))
        : columns,
    [isLoading, columns]
  );

  const [sorting, onSortingChange] = sortingState ?? [];

  const table = useReactTable<TData>({
    data: tableData,
    columns: tableColumns,
    state: { sorting },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    table.setSorting(sortingState?.[0] as SortingState);
  }, [sortingState?.[0]]);

  const styles = useMultiStyleConfig("Table", {
    size,
    variant,
    theme: V2Theme,
  });

  return (
    <TableContainer>
      <ChakraTable variant="list" sx={styles.table} {...rest}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const text = flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                );
                return (
                  <Th
                    key={header.id}
                    {...(header.column.getCanSort()
                      ? { onClick: () => header.column.toggleSorting() }
                      : {})}
                    id={header.id}
                    sx={styles.th}
                    style={{
                      cursor: header.column.getCanSort()
                        ? "pointer"
                        : "inherit",
                      textAlign: header.column.columnDef.meta?.align || "start",
                    }}
                  >
                    {header.column.getCanSort() ? (
                      <Flex>
                        {text}
                        <SortIcon
                          size="1.1em"
                          direction={
                            sorting
                              ? header.column.id === sorting?.at(0)?.id
                                ? sorting?.at(0)?.desc
                                  ? "desc"
                                  : "asc"
                                : null
                              : null
                          }
                        />
                      </Flex>
                    ) : (
                      text
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} sx={styles.tr}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  __css={styles.td}
                  style={{
                    textAlign: cell.column.columnDef.meta?.align || "start",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};
